<!--  -->
<template>
  <div class="news-detail">
    <div class="main">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="新闻标题:">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="摘要:">
          <el-input v-model="form.abstract" maxlength='150'></el-input>
        </el-form-item>
        <el-form-item label="新闻分类">
        <el-select v-model="form.newtypeid" placeholder="请选择">
          <el-option
            v-for="item in newstype"
            :key="item.id"
            :label="item.title"
            :value="item.title">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="排序值(选填):">
          <el-input-number
            v-model="form.sort"
            controls-position="right"
            @change="handleChange()"
            :min="1"
            :max="999"
          ></el-input-number>
          <span class="tip-text"
            >请输入999以内数字，数值越大，新闻排行越高</span
          >
        </el-form-item>
        <el-form-item label="新闻公开:">
          <el-radio-group v-model="form.status">
            <el-radio label="公开"></el-radio>
            <el-radio label="不公开"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="新闻封面:">
          <div class="upload-box">
            <el-upload
              class="cover-upload"
              :action="actionUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess">
              <img v-if="form.img" :src="form.img" class="cover-img" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-button
              icon="el-icon-close"
              size="mini"
              class="cover-delete-icon"
              @click="handleClearImgUrl()"
              circle
            ></el-button>
          </div>
            <div>
              只能上传jpg/png/gif文件，且不超过5M
            </div>
        </el-form-item>
        <el-form-item label="详情:">
        <quill-editor ref="myTextEditor" v-model="form.contents" :options="editorOption"></quill-editor>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleCancel()">取消</el-button>
          <el-button type="primary" @click="handleSubmit()">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        id: '',
        news_no: '',
        title: '', // 标题
        status: '', // 是否公开
        digest: '',
        img: '', // 图片
        sort: 0, // 排序
        abstract: '', // 摘要
        content: [],
        contents: '', // 富文本内容
        newtypeid: '' // 新闻类型
      },
      newtypeidtext: '',
      actionUrl: 'https://service.ddgeyou.com/qifu/api/news/upload',
      textarea: '',
      type: 'add',
      newstype: [{
        value: '1',
        label: '行业资讯'
      },
      {
        value: '2',
        label: '公司动态'
      },
      {
        value: '3',
        label: '新闻动态'
      }],
      value: '',
      content: '<h2>请编辑</h2>',
      editorOption: {},
      nowlangtype: '' // 判断当前语言类型
    }
  },
  methods: {
    // 获取新闻类型
    getNewstype () {
      this.$axios.post('api/news/getnewstypelist', { lang: this.nowlangtype }).then(res => {
        this.newstype = res.data.data
      })
    },
    handleChange () {
    },
    // 清楚封面
    handleClearImgUrl () {
      this.form.img = ''
    },
    // 添加封面回调
    handleAvatarSuccess (res, file) {
      this.form.img = res.data[0]
    },
    // 上传图片
    handleChangeFile (file, fileList, i) {
      if (file.response) {
        this.form.content[i].content = file.response.data[0]
      }
    },
    uploaderror (e) {
    },
    submitUpload (e) {
      /* console.log('没有', e)
      this.$axios.post(e)
        .then(response=>{
        })
        .catch(({err}) => {
        }) */
    },
    // 删除图片
    handleDeletePicture (index) {
      this.form.content.splice(index, 1)
    },
    // 限制图片格式
    beforeAvatarUpload (file) {
      /* const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isGIF = file.type === 'image/gif'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG && isPNG && isGIF) {
        this.$message.error('上传头像图片只能是 JPG/PNG/GIF 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!')
      }
      return isJPG && isLt5M */
    },
    // 添加文本
    handleAddText () {
      this.form.content.push({ type: 1, content: '' })
    },
    // 添加图片
    handleAddImg () {
      this.form.content.push({ type: 2, content: '' })
    },
    // 删除文本
    handleDeleteText (index) {
      this.form.content.splice(index, 1)
    },
    // 提交表单
    handleSubmit () {
      this.getAnalyse()
      if (this.type === 'add') {
        this.$axios.post('api/news/addnews', {
          title: this.form.title,
          lang: this.nowlangtype,
          type: this.newtypeidtext,
          digest: this.form.abstract,
          img: this.form.img,
          is_pub: this.form.status === '公开' ? 1 : 0,
          sort: this.form.sort,
          content: this.form.contents
        }).then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$router.push({ path: 'admin', query: { lang: this.$route.query.lang } })
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      } else {
        this.$axios.post('api/news/updatenewsbyid', {
          id: this.form.id,
          title: this.form.title,
          lang: this.nowlangtype,
          type: this.newtypeidtext,
          digest: this.form.abstract,
          img: this.form.img,
          is_pub: this.form.status === '公开' ? 1 : 0,
          sort: this.form.sort,
          content: this.form.contents
        }).then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.$router.push('/admin')
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      }
    },
    // 取消
    handleCancel () {
      this.$router.push('/admin')
    },
    getAnalyse () {
      this.newstype.forEach((element) => {
        if (element.title === this.form.newtypeid) {
          this.newtypeidtext = element.id
        }
      })
    }
  },
  created () {},
  mounted () {
    if (this.$route.query.lang === 'PT') {
      this.nowlangtype = 'pt-PT'
    } else if (this.$route.query.lang === 'EN') {
      this.nowlangtype = 'en-US'
    } else {
      this.nowlangtype = 'zh-CN'
    }
    this.getNewstype()
    // 通过this.$route.query.id判断当前是从编辑入口进来还是从新创建进来
    if (this.$route.query.id) {
      this.type = 'edit'
      this.$axios.post('api/news/getfrontnewsbyid', { id: this.$route.query.id })
        .then(res => {
          this.form.id = res.data.data.id
          this.form.title = res.data.data.title
          this.form.abstract = res.data.data.digest
          this.form.img = res.data.data.img
          /* this.form.status = res.data.data.is_pub */
          if (res.data.data.status === 1) this.form.status = '公开'
          else this.form.status = '不公开'
          this.form.sort = res.data.data.sort
          this.form.contents = res.data.data.content
          this.form.newtypeid = res.data.data.type
          setTimeout(() => {
            this.newstype.forEach((element) => {
              if (Number(element.id) === Number(res.data.data.type)) {
                this.form.newtypeid = element.title
              }
            })
          }, 100)
        })
    } else {
      this.type = 'add'
    }
  }
}
</script>
<style scoped lang='less'>
.news-detail {
  .main {
    background: white;
    margin: 20px 30px 0 0;
    padding: 20px;
    .tip-text {
      margin-left: 10px;
      color: #999;
    }
    .distribute-news {
      margin-bottom: 10px;
    }
    .upload-box {
      position: relative;
      width: 178px;
      .cover-upload {
        border: 1px dashed #d9d9d9;
        width: 100%;
        height: 178px;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .cover-delete-icon {
        position: absolute;
        top: -12px;
        right: -11px;
        background: black;
        color: white;
      }
      .cover-img {
        width: 178px;
        height: 178px;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .content-top {
      display: flex;
      margin-bottom: 10px;
      .add {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border: 1px solid #000;
        margin-right: 10px;
        .el-icon-edit {
          font-size: 25px;
        }
        .el-icon-picture {
          font-size: 25px;
        }
      }
    }
    .textarea {
      height: 200px;
      background: #efefef;
      padding: 20px;
      overflow-y: scroll;
      .text {
        width: 50%;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        .text-icon {
          position: absolute;
          top: -12px;
          right: -11px;
          background: black;
          color: white;
          font-weight: bold;
        }
      }
      .upload {
        margin-bottom: 20px;
        position: relative;
        height: 100px;
        width: 100px;
        .picture-upload {
          height: 100px;
          width: 100px;
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;
          cursor: pointer;
          .picture {
            position: absolute;
            top: 0;
            left: 0;
            width: 100px;
            height: 100px;
          }
        }
        .delete-icon {
          position: absolute;
          right: -12px;
          top: -11px;
          background: black;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}
.ql-editor{
  height: 300px!important;
}
.editor {
  line-height: normal !important;
  height: 500px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
.el-form-item__label{
  width:150px !important
}
</style>
